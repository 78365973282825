'use es6'

import Carousel from 'react-bootstrap/Carousel';
import{ useState, useEffect } from 'react';
import '../../css/Home.css'

import shoring from '../../images/shoring.jpg'
import caisson from '../../images/Caisson 2.jpg'
import micropiling from '../../images/Micropiling 2.jpg'
import helical from '../../images/Helical.jpg'

import {NAVBAR_ROUTING} from './constants.js'
import {SERVICES} from '../constants/ServiceComponentsConstants'

const AboutUsCarousel = () => {

  const [width, setWidth] = useState( window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setWidth( window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
    })

  return (
    <div>
      <form action={NAVBAR_ROUTING.CONTACT}>
        <button type="submit" className="carousel-contact-button" >
          Contact us {width >= 1024 && "for a quote"} 
        </button>
      </form>
      <Carousel fade>
        <Carousel.Item className="carousel-item">
          <img
            className="d-block w-100 aboutUsImages"
            src={shoring}
            alt="First slide"
          />
          <Carousel.Caption className="carousel-caption">
            <h3><span>{SERVICES.SHORING.title}</span></h3>
            {/* <h3>We put detail above everything</h3>
            <p>Every job we do, we make sure to give it 110%. No cutting corners, just hard work and dedication to the craft. With a combined 15 years of real world engineering experience, we can offer corporate level craftsmanship for small business prices..</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 aboutUsImages"
            src={caisson}
            alt="Second slide"
          />

          <Carousel.Caption className="carousel-caption">
            <h3><span>{SERVICES.CAISSON.title}</span></h3>
            {/* <h3>We take on big jobs, and get big results</h3>
            <p>With our own equipment, we are always ready to take on big projects.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 aboutUsImages"
            src={micropiling}
            alt="Third slide"
          />

          <Carousel.Caption className="carousel-caption">
            <h3><span>{SERVICES.MICROPILE.title}</span></h3>
            {/* <h3>Weather won't stop us.</h3>
            <p>
              Toronto has unpredictable weather, but we are dependable.
            </p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 aboutUsImages"
            src={helical}
            alt="Third slide"
          />

          <Carousel.Caption className="carousel-caption">
            <h3><span>{SERVICES.HELICAL_PILING.title}</span></h3>
            {/* <h3>Weather won't stop us.</h3>
            <p>
              Toronto has unpredictable weather, but we are dependable.
            </p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 aboutUsImages"
            src={SERVICES.DEMOLITION.image}
            alt="Fourth slide"
          />

          <Carousel.Caption className="carousel-caption">
            <h3><span>{SERVICES.DEMOLITION.title}</span></h3>
          </Carousel.Caption>
        </Carousel.Item>
        
        <Carousel.Item>
          <img
            className="d-block w-100 aboutUsImages"
            src={SERVICES.EXCAVATION.image}
            alt="Fifth slide"
          />

          <Carousel.Caption className="carousel-caption">

            <h3><span>{SERVICES.EXCAVATION.title}</span></h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>

  );
}

export default AboutUsCarousel;