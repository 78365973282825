'use es6';

import shoring from '../../images/shoring.jpg'
import Caisson from '../../images/Caisson 2.jpg'
import Micropiling from '../../images/Micropiling.jpg'
import HelicalPiling from '../../images/Helical.jpg'
import Demolition from '../../images/Demolition.jpeg'
import Excavation from '../../images/Excavation.jpeg'


export const SERVICES = {
    SHORING:{
        title: 'Shoring',
        text: 'Shoring is used to support an excavation where an open cut cannot be achieved. Shoring is typically performed by drilling soldier pile holes, inserting steel I-Beams into the holes, and filling the holes with concrete. As the excavation progresses, wooden lagging boards are placed behind the flanges of the steel I-Beams.',
        image: shoring,
        alt: 'Shoring being placed'
    },
    CAISSON:{
        title: 'Caissons',
        text: 'Caissons are foundation elements that are typically constructed by drilling a hole in the ground, placing a rebar cage in the hole, and filling it with high strength concrete.',
        image: Caisson,
        alt: 'Caissons'
    },
    MICROPILE: { 
        title: 'Micro Piles',
        text: 'Micro Piles are foundation elements that are typically constructed by drilling a small diameter hole in the ground, re-enforcing it with bar or strand, and filling the hole with a grout mix.',
        image: Micropiling,
        alt: 'Micro Piles',
    },
    HELICAL_PILING: { 
        title: 'Helical Piles',
        text: 'Helical Piles (also known as screw piles) are foundation elements that are installed via a rotary hydraulic attachment. Helical piles are made from high-strength steel and can be either circular or square in shape.',
        image: HelicalPiling,
        alt: 'Helical Piles',
    },
    DEMOLITION: { 
        title: 'Demolition',
        text: 'We offer demolition services for residential construction.',
        image: Demolition,
        alt: 'Demolition',
    },
    EXCAVATION: { 
        title: 'Excavation',
        text: 'We offer excavation services for residential, commercial, and industrial projects.',
        image: Excavation,
        alt: 'Excavation',
    },
    
};
