'use es6';

import { fromJS } from 'immutable';
import FadeIn from 'react-fade-in';

import ServiceComponent from "./ServiceComponent"
import {SERVICES} from '../constants/ServiceComponentsConstants'


const ServicesContainer = () => {


    return (
        <FadeIn delay={200} transitionDuration={600} >
        { SERVICES && 
            fromJS(SERVICES).keySeq().map( (key, index) => {
                const {image, alt, title, text} = SERVICES[key];
                return(
                    <ServiceComponent key={key} image={image} alt={alt} title={title} text={text} imageIndex={index}/>
                )

            })
        }
        </FadeIn>
    );
}

export default ServicesContainer;