'use es6'

import TextField from '@mui/material/TextField';
import Image from 'react-bootstrap/Image';

import '../../css/ContactUsForm.css';

import contactUs from '../../images/contact us 3.jpg'
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';

import FadeIn from 'react-fade-in';



const ContactUsForm = ({useHeaderImage = true}) => {
    return (
        <>
        <FadeIn delay={200} transitionDuration={600} >
            <form className="contact-us" action="https://formsubmit.co/fa8f5ee88be6b4f143afd5d6fffcc7a0" method="POST">
                {useHeaderImage && (<Image className="contact-us-image" src={contactUs}/>)}
                <h1>
                    Contact us
                </h1>
                <h4>
                If you have any questions or would like to request a quotation, please fill out the form below. 
                </h4>

                <div className="contact-us-fields">
                    
                    <TextField label="First Name" name="first name" required={true} variant="outlined"/>
                    <TextField label="Last Name" name="last name" required={true} variant="outlined"/>
                </div>
                    
                <div className="contact-us-fields"> 
                    <TextField label="Email Address" type="email" name="email" required={true} variant="outlined" />
                    <TextField label="Phone Number" name="phone number" required={true}  variant="outlined"/>
                </div>
                
                <TextField rows={10} className='query' label="Query" name="query" required={true} multiline={true} variant="outlined"  />

                <Button type="submit" variant="contained" className="contact-us-send" > Send <SendIcon fontSize="inherit"/> </Button>

            </form>
        </FadeIn>
        </>
    );
}

export default ContactUsForm;