'use es6'

import '../../css/Footer.css'

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {

    const hasSocial = false;

    return (

        <div className="footer">

            <div className="footer-items">
                <div className="footer-item">
                    <PhoneInTalkIcon fontSize="inherit"/> 
                    +1 (416) 831 9749
                </div>  
                <div className="footer-item">
                    <MailOutlineIcon fontSize="inherit"/> 
                        <a href="/contact"> estimating@geo-co.ca </a>
                </div>..
            </div>
            {hasSocial && (
            <div className="footer-items-social">
                <LinkedInIcon fontSize="inherit"/>
                <TwitterIcon fontSize="inherit"/>
                <FacebookIcon fontSize="inherit"/>
                <InstagramIcon fontSize="inherit"/>
            </div>
            )}
            
        </div>

    );
}

export default Footer;

