'use es6'

import ContactMailIcon from '@mui/icons-material/ContactMail';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import WorkIcon from '@mui/icons-material/Work';

export const NAVBAR_ITEMS = {
    ABOUT: { 
        text: 'Services',
        icon: <LocalShippingIcon/>
    },
    BRAND: 'Geo Co',
    CONTACT: { 
        text: 'Contact us',
        icon: <ContactMailIcon/>
    },
    HOME: { 
        text: 'Home',
        icon: <HomeIcon/>
    },
    // CAREERS: {
    //     text: 'Careers',
    //     icon: <WorkIcon/>
    // },
}

export  const NAVBAR_ROUTING = {
    HOME: "/home",
    ABOUT: '/services',
    CONTACT: '/contact',
    // CAREERS: '/careers',
    
};
