'use es6';

import '../../css/ServiceComponent.css'
import{ useState, useEffect } from 'react';


const ServiceComponent = ({image, alt, imageIndex, title, text}) => {

    const [width, setWidth] = useState( window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setWidth( window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
    })
    
    return (
        <>
            {(imageIndex % 2 === 0 ||  width <= 1025)
                ? (
                    <div className="service-component-div">
                        <div className="service-component-text">
                            <h1 className='h1-left'>{title}</h1>
                            {text}
                        </div>
                        <img className="service-component-image" alt={alt} src={image}/>
                    </div>
                    
                ) : (
                    <div className="service-component-div">
                        <img className="service-component-image" alt={alt} src={image}/>
                        <div className="service-component-text">
                            <h1 className='h1-left'>{title}</h1>
                            {text}
                        </div>
                    </div>
                )
            }
        </>

    );
}

export default ServiceComponent;

