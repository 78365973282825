'use es6'

import AboutUsCarousel from './AboutUsCarousel';
import '../../css/Home.css';
import '../../css/AboutUsContainer.css'
import ContactUsForm from './ContactUsForm'

import FadeIn from 'react-fade-in';


const Home = () => {
 
    return (
        <div className="home">
            <FadeIn delay={200} transitionDuration={600} >
                <AboutUsCarousel />
                <ContactUsForm useHeaderImage={false}/>
            </FadeIn>
        </div>
    );
}

export default Home;