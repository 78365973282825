'use es6'


import Button from '@mui/material/Button';
import { fromJS } from 'immutable';


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import {NAVBAR_ITEMS, NAVBAR_ROUTING} from './constants.js'
import '../../css/NavigationBar.css'
import logo from '../../images/logo.jpg'




const NavigationBar = () => {

    //memoization for the navbar elements so that they dont have to be recalculated everytime the accordion opens

    return (
        <Navbar collapseOnClick className="navbar" bg='light' expand='lg' sticky='top'>
            <Container display="flex" className='container'>
                <Navbar.Brand className="brand" href={NAVBAR_ROUTING.HOME}> 
                    <img
                        src={logo}
                        width="100%"
                        height="50"
                        className="d-inline-block align-top"
                        alt="Geo Co logo"
                    />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="navbar-collapse">
                    <Nav className="d-flex"  defaultActiveKey="#home">
                        { NAVBAR_ROUTING && 
                            fromJS(NAVBAR_ROUTING).keySeq().map( (key, index) => {

                                return(
                                    <Nav.Link className="navbar-link" key={key} href={NAVBAR_ROUTING[key]}>
                                            <Button  className="navbar-button-div" variant="text">{NAVBAR_ITEMS[key].icon} {NAVBAR_ITEMS[key].text} </Button>
                                    </Nav.Link>
                                )

                            })
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
   
}

export default NavigationBar;