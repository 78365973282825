import './css/Global.css'
import NavigationBar from './js/components/NavigationBar';
import Home from './js/components/Home';
import ContactUsForm from './js/components/ContactUsForm';
import ServicesContainer from './js/components/ServicesContainer';
import Footer from './js/components/Footer';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";


const App = () => {
  return (
    <>
    
      <NavigationBar />
      <BrowserRouter>
      <>

        <Routes>
          <Route path="/home" element={<Home />} /> 
          <Route path="/" element={<Home />} /> 
          <Route path="/contact" element={<ContactUsForm />} /> 
          <Route path="/services" element={<ServicesContainer />} /> 
          
        </Routes>
      </>
    </BrowserRouter>
      
      <Footer />
    </>
  );
}

export default App;
